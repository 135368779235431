import React, { useEffect } from "react"
import { useInputChange } from "../custom-hook/useForm"
import AccountInput from "./accountInput"
import CountryForm from "./countryInput"
import DateForm from "./dateForm"
import "../assets/styles/components/accountManager.scss"

const AccountManager = (props: { [keys: string]: any }) => {
  const { setIsDisabled, formInputRef } = props
  const [input, handleInputChange] = useInputChange()

  const isCompleted =
    !input.managerFirstName ||
    !input.managerLastName ||
    !input.managerCountry ||
    !input.managerStreet ||
    !input.managerCity ||
    !input.managerState ||
    !input.managerZipCode ||
    !input.managerPlaceOfBirth ||
    !input.managerCitizenship

  useEffect(() => {
    setIsDisabled(isCompleted)
    if (Object.keys(input).length != 0) Object.assign(formInputRef.current, input)
  }, [isCompleted, input])

  return (
    <div className="account-manager">
      <h3 className="h3-heading">Add an account manager</h3>
      <span className="desc-1">
        Make sure everything’s accurate so we can verify your account.
        <span>
          <button className="learn-btn">Learn more</button>
        </span>
      </span>
      <div className="section-1">
        <div className="desc-2">
          <h6>Legal name</h6>
          <p className="description">This should match an official document, like a government ID.</p>
        </div>
      </div>

      <div className="manager-name-form">
        <AccountInput
          type={"text"}
          name={"managerFirstName"}
          placeholder={" "}
          onChange={handleInputChange}
          label={"First Name"}
          required={true}
        />

        <AccountInput
          type={"text"}
          name={"managerLastName"}
          placeholder={" "}
          onChange={handleInputChange}
          label={"Last Name"}
          required={true}
        />
      </div>

      <div className="section-2">
        <div className="desc-2">
          <h6>Home address</h6>
          <p className="description">The address for their primary residence (it’s usually on utility bills).</p>
        </div>
        <div className="subsection">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">Country / Region</p>
                <CountryForm name={"managerCountry"} onChange={handleInputChange} required={true} />
              </div>
            </div>
          </div>
        </div>
        <div className="subsection">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">Street</p>
                <AccountInput name={"managerStreet"} onChange={handleInputChange} type={"text"} required={true} />
                <span className="subscript">House name/number + street/road</span>
              </div>
            </div>
          </div>
        </div>
        <div className="subsection">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">Apt, Suite. (optional)</p>
                <AccountInput name={"managerApt_suite"} onChange={handleInputChange} type={"text"} required={false} />
                <span className="subscript">Apt., suite, building access code</span>
              </div>
            </div>
          </div>
        </div>

        <div className="subsection-2">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">City</p>
                <AccountInput name={"managerCity"} onChange={handleInputChange} type={"text"} required={true} />
              </div>
            </div>
          </div>
        </div>

        <div className="subsection-2">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">State</p>
                <AccountInput name={"managerState"} onChange={handleInputChange} type={"text"} required={true} />
              </div>
            </div>
          </div>
        </div>

        <div className="subsection-2">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">ZIP Code</p>
                <AccountInput name={"managerZipCode"} onChange={handleInputChange} type={"text"} required={true} />
              </div>
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </div>

      <div className="section-1">
        <div className="title-spacing">
          <h6>Date of birth</h6>
        </div>
        <DateForm name={"managerDateOfBirth"} onChange={handleInputChange} required={true} />
      </div>

      <div className="section-1">
        <div className="title-spacing">
          <h6>Place of birth</h6>
        </div>

        <CountryForm
          name={"managerPlaceOfBirth"}
          label={"Country/Region"}
          onChange={handleInputChange}
          required={true}
        />
      </div>

      <div className="section-1">
        <div className="title-spacing">
          <h6>Citizenship</h6>
        </div>
        <CountryForm
          name={"managerCitizenship"}
          label={"Country/Region"}
          onChange={handleInputChange}
          required={true}
        />
      </div>
    </div>
  )
}

export default AccountManager
